<script context="module" lang="ts">
  import { CdnImageDataFragment } from 'gatsby/graphqlTypes';
  import { loggers } from 'lib/log';
  import { ImageKind } from '../types';
  import { isNum, isString, makeClassNames } from 'lib/util';
  import { validateClassName } from '../validate';

  const log = loggers.ui;

  function handleError(event: CustomEvent) {
    log.error(new Error('Image loading error'), event.detail);
  }
</script>

<script lang="ts">
  export let height: string | number = 'h-full';
  export let className: string | null = null;
  export let display: string | null = null;
  export let gravity: ImageGravity | null = null;
  export let colour: ImageColour | null = null;
  export let crop: ImageCrop | null = null;
  export let zoom: number | null = null;
  export let blur: ImageBlur | null = null;
  export let eager: boolean = false;
  export let objectFit: ObjectFit | null = null;
  export let data: CdnImageDataFragment;

  validateClassName(className, data);
</script>

<!-- NOTE: hashToImage will default display to block -->
<hash-to-image
  k={`${ImageKind.FullWidth}`}
  bl={blur && JSON.stringify(blur)}
  color={colour && JSON.stringify(colour)}
  bps={JSON.stringify(data.cdn?.breakpoints)}
  class={makeClassNames('w-screen', className, isString(height) && height)}
  dis={display}
  fit={objectFit}
  src={`${process.env.GATSBY_IMAGES_ENDPOINT}/${data.cdn?.fileName}`}
  alt={data.alt}
  z={zoom}
  eager={eager ? 'true' : null}
  hash={JSON.stringify(data.thumbhash)}
  {gravity}
  {crop}
  style:height={isNum(height) ? `${height}px` : null}
  height={isNum(height) ? height : null}
  on:error={handleError}
/>
