import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import { useEffect, useRef } from 'react';

import { MagicLinkStateMachine } from '../magic-link/magicLinkStateMachine';
import useAppEventDispatcher from 'lib/events/hooks';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';

export function useRequestMagicLinkSub() {
  const unsubscribe = useRef(() => {});
  const eventDispatcher = useAppEventDispatcher();
  const worker = useGraphqlWorker();

  const subscribe = useRef(
    (
      variables: GqlSchemaWorker.RequestMagicLinkSubscriptionVariables,
      callback: Parameters<MagicLinkStateMachine['subscribe']>[2]
    ) => {
      const stateMachine = new MagicLinkStateMachine(worker);
      stateMachine.subscribe(variables, eventDispatcher, callback);

      unsubscribe.current = stateMachine.unsubscribe.bind(stateMachine);
    }
  );

  useEffect(() => {
    return () => {
      unsubscribe.current();
    };
  }, []);

  return {
    unsubscribe: unsubscribe.current,
    subscribe: subscribe.current
  };
}
