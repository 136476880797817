import * as Comlink from 'comlink';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import {
  DisruptorCombinedError,
  makeMutationPromise,
  parseOpResult
} from '@svelte/service/backend/queryStore';
import { RequestMagicLinkState, RequestMagicLinkStateKind } from './types';

import { AppEventName } from 'lib/events/contracts';
import { AppEventTarget } from 'lib/events/globalEvents';
import { DisruptorServiceWorkerAsync } from '@svelte/service/worker/types';
import { assertUnreachable } from 'lib/util';
import { loggers } from 'lib/log';

const log = loggers.service;

export class MagicLinkStateMachine {
  private retryDelay: number | NodeJS.Timeout | null = null;
  private graphqlSubscriptionId: string | null = null;
  state: RequestMagicLinkState;

  constructor(readonly worker: DisruptorServiceWorkerAsync) {
    this.state = {
      kind: RequestMagicLinkStateKind.Ready
    };
  }

  private clearInterval() {
    if (this.retryDelay) {
      clearInterval(this.retryDelay);
    }
  }

  private setReady() {
    this.clearInterval();
    log.debug('MagicLinkStateMachine status: ready');
    this.state = { kind: RequestMagicLinkStateKind.Ready };
    if (this.graphqlSubscriptionId) {
      this.worker.unsubscribe(this.graphqlSubscriptionId);
      this.graphqlSubscriptionId = null;
    }
  }

  private setError(error: DisruptorCombinedError, retryDelay?: number) {
    if (!retryDelay) {
      log.error(new Error('State machine error'), { error });
    }
    this.state = {
      kind: RequestMagicLinkStateKind.Error,
      retryDelay: retryDelay || 0,
      error
    };
  }

  private setProgress(
    variables: GqlSchemaWorker.RequestMagicLinkSubscriptionVariables,
    data?: GqlSchemaWorker.RequestMagicLinkSubscription['requestMagicLink']
  ) {
    this.state = {
      kind: RequestMagicLinkStateKind.InProgress,
      data,
      variables
    };
  }

  async subscribe(
    variables: GqlSchemaWorker.RequestMagicLinkSubscriptionVariables,
    eventDispatcher: AppEventTarget,
    callback: (message: RequestMagicLinkState) => void
  ) {
    if (this.graphqlSubscriptionId) {
      return;
    }

    eventDispatcher.dispatch(AppEventName.MagicLinkRequested, {
      email: variables.email
    });

    this.setProgress(variables);

    this.graphqlSubscriptionId = await this.worker.graphqlSubscription(
      { key: 'requestMagicLink', variables },
      Comlink.proxy(response => {
        const { data, error } = parseOpResult<'requestMagicLink'>(response);
        if (data) {
          const magicLink = data.requestMagicLink;
          if (magicLink) {
            log.info('Received MagicLink status', { status: magicLink.status });
            this.setProgress(variables, magicLink);

            switch (magicLink.status) {
              case 'ERROR':
              case 'EXPIRED':
                eventDispatcher.dispatch(AppEventName.MagicLinkUsed, {
                  result: magicLink.status
                });
                this.unsubscribe();
                break;
              case 'EXCHANGED':
                eventDispatcher.dispatch(AppEventName.MagicLinkFlowComplete);
                // don't enable refetch on EXCHANGED yet. See LogIn for reasons
                this.unsubscribe();
                break;
              case 'REQUESTED':
              case 'UNSPECIFIED':
                break;
              case 'SENT':
                //
                // Avoid race authentication race conditions
                // in case user opens link in same browser
                // and comes back to this tab before
                // new cookie is received from backend
                //
                // disableRefetchDefault(queryClient);
                break;
              case 'EXCHANGED_IN_DIFFERENT_SESSION':
                eventDispatcher.dispatch(AppEventName.MagicLinkUsed, {
                  result: magicLink.status
                });
                const token = magicLink.signedToken;
                if (!token) {
                  const error = new Error('No token sent after exchange');
                  log.error(error, { magicLink });
                } else {
                  makeMutationPromise({
                    key: 'exchangeLoginTokenDirect',
                    variables: { token },
                    worker: this.worker
                  })
                    .then(() => {
                      this.unsubscribe();
                    })
                    .catch(error => this.setError(error));
                }

                break;
              default:
                assertUnreachable(magicLink.status);
            }
          }

          callback(this.state);
        }

        if (error) {
          const retryInfo = error.disruptorExtensions?.resourceExhausted();

          if (retryInfo) {
            log.warn('Email request throttled', { retryInfo });
            if (!this.retryDelay) {
              this.retryDelay = setInterval(() => {
                const nextDelay =
                  this.state.kind === RequestMagicLinkStateKind.Error
                    ? this.state.retryDelay - 1
                    : 0;
                if (nextDelay > 0) {
                  this.setError(error, nextDelay);
                } else {
                  this.setReady();
                }
                callback(this.state);
              }, 1000);
            }

            this.setError(error, retryInfo?.retry_delay);
          } else {
            log.error(new Error('Fatal error when requesting magic link'), {
              error
            });
            this.setError(error);
            this.unsubscribe();
          }

          callback(this.state);
        }
      })
    );
  }

  unsubscribe() {
    log.info('Unsubscribing request magic link');
    this.setReady();
  }
}
