import { AppEventName } from 'lib/events/contracts';
import { BodyText, InterfaceText, TextSize } from 'components/atoms/typography';
import { ExternalLink, InternalLink } from 'components/atoms/typography/Link';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import { List, ListItem } from 'components/atoms/typography/List';
import React, { useEffect, useState } from 'react';
import { assertUnreachable, canUseDom, isEmpty } from 'lib/util';

import EmailNewAnimated from 'components/atoms/icons/EmailNew';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/molecules/error/ErrorFallback';
import { ErrorsSummary } from 'components/molecules/forms/Error';
import { Form } from 'react-final-form';
import type { FormSubscription } from 'final-form';
import InfoBox from 'components/molecules/info/Box';
import { Input } from 'components/molecules/forms/Input';
import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
import MagicWand from '@carbon/icons-react/es/magic-wand--filled/24';
import { NotificationErrorLevel } from '@svelte/lib/notifications/types';
import RateLimitInfoBox from 'components/molecules/forms/RateLimitInfo';
import {
  RequestMagicLinkStateKind,
  RequestMagicLinkState
} from 'service/magic-link/types';
import { SubmitButton } from 'components/atoms/button/SubmitButton';
import { loggers } from 'lib/log';
import { navigate } from 'gatsby';
import useAppEventDispatcher from 'lib/events/hooks';
import useBroadcastChannel from 'lib/hooks/useBroadcastChannel';
import { useRequestMagicLinkSub } from 'service/hooks/user';
import { useRoutePath } from 'state/context/nav';
import useValidationSchema from 'lib/hooks/useValidationSchema';
import { useCustomer, useSessionEmail } from 'service/hooks/session';

const log = loggers.service;

type FormData = Omit<
  GqlSchemaWorker.RequestMagicLinkSubscriptionVariables,
  'redirectPath'
>;

const formSubscription: FormSubscription = {
  dirtySinceLastSubmit: true,
  errors: true,
  submitErrors: true,
  submitFailed: true,
  submitSucceeded: true,
  submitting: true,
  touched: true
};

/**
 * Login Flow with react-query gotchas:
 *
 * Refetching on window refocus can lead to authentication race condition.
 * The trickiest edge case is:
 * 1. Request link
 * 2. Open link in a tab of same browser
 * 3. Quickly switch back to first tab (before app renders)
 * 4. Authentication query will refetch, but it may be called before
 *    the new cookie has been received and set.
 *
 * In order to make it resilient:
 * - Disable refetching when login flow is going on
 * - When exchange is detected in same browser, reissue mutation to authentication AFTER
 *   app has settled and the other tab has send the message. The other tab has a delayed to send
 *   message to make sure cookie is set.
 * - Either flow (same browser, or in another) should finish with re-enabling refetch
 *
 * However, this doesn't work 100%. When this race condition happens, an errors will be thrown
 * to the boundary and we'll know with Sentry
 *
 * UPDATE 9/02/2023
 * `useSession` is now a Query without side effects. Gateway won't reauthenticate when called.
 * This seems to solve the issue with race conditions.
 *
 */

const LoginForm: React.FC = () => {
  const homeRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.HOME);
  const accountOverviewRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.ACCOUNT);
  const redirectPath =
    (canUseDom() && history?.state?.redirectPath) || homeRoute.path;
  const loginMessage: string | undefined =
    canUseDom() && history?.state?.loginMessage;
  const loginChannel = useBroadcastChannel('login');
  const [exchangedInAnotherTab, setExchangedInAnotherTab] = useState(false);
  const requestMagicLinkSub = useRequestMagicLinkSub();
  const { data: user } = useCustomer();
  const [submitted, setSubmitted] = useState(false);
  const [requestState, setRequestState] = useState<RequestMagicLinkState>({
    kind: RequestMagicLinkStateKind.Ready
  });
  const sessionEmail = useSessionEmail();
  const inProgress = requestState.kind === RequestMagicLinkStateKind.InProgress;
  const inError = requestState.kind === RequestMagicLinkStateKind.Error;
  const rateLimitRemaining = inError ? requestState.retryDelay : 0;
  const magicLinkStatus = inProgress ? requestState.data?.status : null;
  const initialValues: FormData = {
    email: inProgress ? requestState.variables.email : sessionEmail || ''
  };
  const eventDispatcher = useAppEventDispatcher();

  useEffect(() => {
    if (loginChannel) {
      loginChannel.onmessage = () => {
        setExchangedInAnotherTab(true);
      };
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (!submitted) {
        /**
         * Guard against logged-in user arriving at login page
         */
        navigate(accountOverviewRoute.path);
      } else {
        /**
         * Login with direct token has ended
         * Time to see the target page!
         */
        navigate(redirectPath);
      }
    }
  }, [user]);

  useEffect(() => {
    /**
     * Browser has received the new cookie from server
     * We can now continue with new session
     */
    if (exchangedInAnotherTab) {
      log.info('Token exchanged in another tab. Refreshing session');

      eventDispatcher.dispatch(AppEventName.LoggedInAnotherTab);
    }
  }, [exchangedInAnotherTab]);

  const validate = useValidationSchema<FormData>(Yup => {
    return Yup.object({
      email: Yup.string()
        .required('An email is required')
        .email('Invalid email address')
    });
  });

  const onSubmit = async (values: FormData) => {
    const payload = {
      ...values,
      redirectPath
    };

    setSubmitted(true);

    const validation = await new Promise((resolve, reject) => {
      requestMagicLinkSub.subscribe(payload, requestState => {
        setRequestState(requestState);

        const kind = requestState.kind;
        switch (kind) {
          case RequestMagicLinkStateKind.Ready:
            resolve({});
            break;
          case RequestMagicLinkStateKind.Error:
            const validationError =
              requestState.error.disruptorExtensions.badUserInput();

            // Only end submission if it's an unrecoverable error
            // If it's request throttling, wait, until state goes back to ready
            if (validationError) {
              return resolve(validationError.fields);
            }
            break;
          case RequestMagicLinkStateKind.InProgress:
            const status = requestState.data?.status;

            if (status) {
              switch (status) {
                case 'EXPIRED':
                  return reject(new Error('Magic Link expired'));
                case 'UNSPECIFIED':
                case 'ERROR':
                  return reject(
                    new Error(
                      `Email magic link login failed. Status code: ${status}`
                    )
                  );
                case 'REQUESTED':
                case 'SENT':
                  // wait until flow finishes either with end of throttling or success
                  break;
                case 'EXCHANGED':
                  // token has been exchanged in another tab
                  // but session cookie may not be set yet
                  // so we wait for message from loginChannel
                  // before side-effects
                  // and also handle browsers which don't support BroadcastChannel
                  if (!loginChannel) {
                    log.info(
                      'Broadcast channel not supported for exchanged status'
                    );
                    setTimeout(() => {
                      setExchangedInAnotherTab(true);
                      resolve({});
                    }, 3000);
                  }

                  break;
                case 'EXCHANGED_IN_DIFFERENT_SESSION':
                  // Service will have requested a direct token
                  // don't return yet
                  break;
                default:
                  assertUnreachable(status);
              }
            }
            break;
          default:
            assertUnreachable(kind);
        }
      });
    });

    setSubmitted(false);
    requestMagicLinkSub.unsubscribe();
    return validation;
  };

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      subscription={formSubscription}
      render={({
        errors,
        submitErrors,
        handleSubmit,
        dirtySinceLastSubmit,
        submitFailed,
        submitting
      }) => {
        const formErrors = errors && !isEmpty(errors) ? errors : submitErrors;

        return (
          <div className="relative">
            <form
              className="mx-auto max-w-lg lg:max-w-none"
              onSubmit={handleSubmit}
            >
              {loginMessage && (
                <div className="mb-rhythm1 rounded-sm border border-red-800 bg-red-300 p-rhythm-1">
                  <InterfaceText
                    size={TextSize.LongPrimer}
                    color="text-red-800"
                  >
                    {loginMessage}
                  </InterfaceText>
                </div>
              )}
              <Input
                name="email"
                disabled={inProgress}
                label="Email address"
                type="email"
                autoComplete="email"
              />

              {rateLimitRemaining > 0 ? (
                <RateLimitInfoBox
                  rateLimitRemaining={rateLimitRemaining}
                  message="We recently sent a magic link to this address. Please
                  wait before requesting another."
                />
              ) : magicLinkStatus ? (
                <MagicLinkHandler
                  resetForm={requestMagicLinkSub.unsubscribe}
                  status={magicLinkStatus}
                />
              ) : (
                <>
                  <SubmitButton
                    className="mt-rhythm0"
                    hasError={submitFailed && !dirtySinceLastSubmit}
                    isSubmitting={submitting || inProgress}
                    disabled={submitFailed && !dirtySinceLastSubmit}
                    text="Login With Email"
                    submittingText="Requesting link"
                  />

                  {!submitFailed && (
                    <InfoBox className="mt-rhythm0">
                      <BodyText withoutSpacing size={TextSize.LongPrimer}>
                        <MagicWand className="mr-1 inline-block text-primary" />
                        We’ll email you a magic code for a password-free login
                        even if you don't have an account yet.
                      </BodyText>
                    </InfoBox>
                  )}

                  <ErrorsSummary
                    headingLevel={2}
                    className="pt-rhythm0"
                    errors={submitFailed ? formErrors : undefined}
                  />
                </>
              )}
            </form>
          </div>
        );
      }}
    ></Form>
  );
};

function contentOfStatus(
  resetForm: () => void,
  status: GqlSchemaWorker.MagicLinkStatus
): React.ReactNode | null {
  const contactUsRoute = useRoutePath(MAIN_NAVIGATION_ROUTER_KEY.CONTACT_US);
  switch (status) {
    case 'EXPIRED':
    case 'UNSPECIFIED':
    case 'ERROR':
    case 'REQUESTED': // TODO: I don't think Requested is very meaningful, as backend will take ms to transit to send
      return (
        <InfoBox>
          <div className="flex items-center">
            <MagicWand className="mr-4 h-rhythm2 w-rhythm2 text-primary" />
            <BodyText withoutSpacing>
              Preparing email with your login link. Please wait...
            </BodyText>
          </div>
        </InfoBox>
      );
    case 'SENT':
      return (
        <>
          <InfoBox
            level={NotificationErrorLevel.Success}
            className="mb-rhythm4"
          >
            <div className="mb-rhythm0 w-full lg:flex lg:space-x-3">
              <EmailNewAnimated className="h-rhythm4 w-rhythm4 text-emerald-500" />

              <BodyText withoutSpacing>
                Success!! Please follow the link we have sent to your inbox.
              </BodyText>
            </div>

            <BodyText size={TextSize.LongPrimer} withoutSpacing>
              Feel free to use a different device. This screen will update
              automatically.
            </BodyText>
          </InfoBox>
          <div className="border-t border-primary pt-rhythm0">
            <BodyText
              withoutSpacing
              size={TextSize.LongPrimer}
              className="mb-rhythm-2"
            >
              If you don't receive an email within 5 minutes:
            </BodyText>
            <List withoutSpacing>
              <ListItem>
                <BodyText withoutSpacing size={TextSize.LongPrimer}>
                  Check the email address above is correct. If not, please{' '}
                  <ExternalLink url="" onClick={resetForm}>
                    request another
                  </ExternalLink>
                  .
                </BodyText>
              </ListItem>
              <ListItem>
                <BodyText withoutSpacing size={TextSize.LongPrimer}>
                  Check your spam folder.
                </BodyText>
              </ListItem>
              <ListItem>
                <BodyText withoutSpacing size={TextSize.LongPrimer}>
                  Please{' '}
                  <InternalLink to={contactUsRoute.path}>
                    contact us
                  </InternalLink>{' '}
                  so that we can help you.
                </BodyText>
              </ListItem>
            </List>
          </div>
        </>
      );
    case 'EXCHANGED':
    case 'EXCHANGED_IN_DIFFERENT_SESSION':
      return <BodyText>Login successful. Please wait...</BodyText>;
    default:
      assertUnreachable(status);
  }
}

export const MagicLinkHandler: React.FC<{
  status: GqlSchemaWorker.MagicLinkStatus;
  resetForm: () => void;
}> = ({ resetForm, status }) => {
  const content = contentOfStatus(resetForm, status);

  if (!content) {
    return null;
  }
  return <>{content}</>;
};

const LoginFormWithBoundary: React.FC = props => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoginForm {...props} />
    </ErrorBoundary>
  );
};

export default LoginFormWithBoundary;
