import { AppPageProps } from 'gatsby/types';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import { Heading } from 'components/atoms/typography';
import LoginForm from 'components/organisms/forms/LogIn';
import { LoginPageQuery } from 'gatsby/graphqlTypes';
import PageContentLayout from 'components/layouts/PageContentLayout';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import { graphql } from 'gatsby';

export { Head } from 'components/atoms/Head';
export type Props = AppPageProps<LoginPageQuery>;

export const query = graphql`
  query LoginPage {
    c: strapiLoginPage {
      title
      image {
        ...CdnImageData
      }
    }
  }
`;

const Login: React.FC<Props> = props => {
  const data = props.data.c;
  const image = data?.image;
  const pageContext = props.pageContext;
  if (!data || !image) {
    throw Error('no data');
  }

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <FullWidthImage
        display="block lg:hidden"
        data={image}
        height="h-rhythm10 md:h-rhythm11"
        eager
      />
      <div className="min-h-screen lg:flex">
        <div className="px-4 py-rhythm4 sm:px-6 lg:px-12 lg:pt-rhythm6 xl:px-20 2xl:px-32">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              {/* <Heading level={1}>{data.pageHeading}</Heading> */}
              <Heading level={1}>{data.title}</Heading>
            </div>

            <div className="mt-rhythm4">
              <LoginForm />
            </div>
          </div>
        </div>

        <ResponsiveImage
          width="flex-1"
          height="h-auto"
          display="hidden lg:block"
          data={image}
          eager
        />
      </div>
    </PageContentLayout>
  );
};

export default Login;
