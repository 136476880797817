import React from 'react';
import { makeClassNames } from 'lib/util';

type Props = {
  className?: string;
};

// Based on EmailNew carbon icon
// NOTE: used in LogIn form with h-rhythm4 w-rhythm4, the pulsing notification's scale is hardcoded to that
const EmailNewAnimated: React.FC<Props> = ({ className }) => {
  return (
    <div className={makeClassNames(className, 'relative h-rhythm4 w-rhythm4')}>
      <svg
        className="h-rhythm4 w-rhythm4"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="currentColor"
      >
        <title>New Email</title>
        <path d="M19,24H4L3.9966,8.9062l11.4341,7.9161a1.0008,1.0008,0,0,0,1.1386,0L28,8.9087,28,18h2V8a2.0027,2.0027,0,0,0-2-2H4A2.0023,2.0023,0,0,0,2,8V24a2.0027,2.0027,0,0,0,2,2H19ZM25.7986,8,16,14.7837,6.2014,8Z" />
        {/* <circle cx="26" cy="24" r="4" /> */}
        <rect className="fill-transparent" width="32" height="32" />
      </svg>
      <span className="absolute bottom-2 right-1 flex h-3 w-3">
        <span className="absolute inline-flex h-3 w-3 animate-ping rounded-full bg-emerald-400 opacity-75"></span>
        <span className="relative inline-flex h-3 w-3 rounded-full bg-emerald-500"></span>
      </span>
    </div>
  );
};

export default EmailNewAnimated;
