import { BodyText, Heading } from 'components/atoms/typography';
import React, { useEffect } from 'react';

import { FallbackProps } from 'react-error-boundary';
import { TextButton } from 'components/atoms/button';
import { loggers } from 'lib/log';

const log = loggers.ui;

const ErrorFallback: React.FC<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  useEffect(() => {
    log.error(new Error('Error caught in boundary fallback'), { error });
  }, []);

  return (
    <div className="flex h-full w-full items-center" role="alert">
      <div className="w-full rounded border border-red-500 bg-red-100 p-rhythm0">
        <Heading level={3} color="text-red-500" withoutSpacing>
          Something went wrong
        </Heading>
        <BodyText>{error.message}</BodyText>
        <TextButton onClick={resetErrorBoundary}>Try again</TextButton>
      </div>
    </div>
  );
};

export default ErrorFallback;
