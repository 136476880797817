import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import { DisruptorCombinedError } from '@svelte/service/backend/queryStore';

export const enum RequestMagicLinkStateKind {
  Ready,
  InProgress,
  Error
}

export type RequestMagicLinkState =
  | {
      kind: RequestMagicLinkStateKind.Ready;
    }
  | {
      kind: RequestMagicLinkStateKind.InProgress;
      data?: GqlSchemaWorker.RequestMagicLinkSubscription['requestMagicLink'];
      variables: GqlSchemaWorker.RequestMagicLinkSubscriptionVariables;
    }
  | {
      kind: RequestMagicLinkStateKind.Error;
      retryDelay: number;
      error: DisruptorCombinedError;
    };
